<template>
  <v-form>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h3>จัดการลอตเตอรี่</h3>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" md="4" sm="12">
            <!-- <v-menu
              ref="menufrom"
              v-model="menufrom"
              :close-on-content-click="false"
              :return-value.sync="datefrom"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datefrom"
                  label="วันที่เริ่ม"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  solo
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datefrom"
                no-title
                scrollable
                :min="firstDate"
              >
                <v-btn text color="primary" @click="menufrom = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menufrom.save(datefrom)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu> -->
            <v-menu
              v-model="menufrom"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datefrom"
                  label="เลือกงวดที่ออกรางวัล"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datefrom"
                @input="menufrom = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-btn color="primary" @click="getAllLotto()">ค้นหา</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn @click="goToCreate()" color="primary" dark
              >เพิ่มลอตเตอรี่</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersLotto"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:[`item.urlImage`]="{ item }">
              <v-img
                :src="item.urlImage"
                max-height="50px"
                max-width="100px"
                class="ma-2"
              ></v-img>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <!-- <v-icon @click="viewForm3(item)">mdi-eye</v-icon> -->
                <v-icon @click="goToDupp(item)">mdi-content-copy</v-icon>
                <v-icon @click="UpdateLotto(item)" class="mx-2"
                  >mdi-pencil</v-icon
                >
                <v-icon @click="DeleteLotto(item)">mdi-delete</v-icon>
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { Encode, Decode } from "@/services";
export default {
  data() {
    return {
      headersLotto: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "รูป", value: "urlImage", align: "center" },
        { text: "หมายเลข", value: "lotteryNo", align: "center" },
        { text: "งวดประจำวันที่", value: "lotteryDate", align: "center" },
        { text: "จำนวน Stock", value: "stock", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
        // count: 1
        // createdAt: "2021-04-24T18:16:52.000Z"
        // id: 132
        // lotteryDate: (...)
        // lotteryNo: 338541
        // owner: "admin"
        // price: 80
        // set: 51
        // stock: 0
        // time: 28
        // updatedAt: "2021-05-10T11:06:09.000Z"
        // urlImage: "https://readylotservice.readylotte
      ],
      items: [],
      search: "",
      count: 0,
      user: "",
      datefrom: "",
      menufrom: false,
    };
  },
  created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
    this.getAllLotto();
  },
  methods: {
    async getAllLotto() {
      console.log(this.datefrom);
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const data = {
        id: "",
        lotteryDate: "",
        stock: "",
        owner: "",
      };

      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/lottery/getAll?lotteryDate=` +
          this.datefrom,
        auth,
        data
      );
      console.log("getAllLotto", response.data.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
    goToDupp(val) {
      localStorage.setItem("Lottodata", Encode.encode(val));
      this.$router.push("DupplicateLotto");
    },
    UpdateLotto(val) {
      localStorage.setItem("Lottodata", Encode.encode(val));
      this.$router.push("EditLotto");
    },
    async DeleteLotto(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/lottery/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllLotto();
        }
      });
    },
    goToCreate() {
      this.$router.push("createLotto");
    },
  },
};
</script>
